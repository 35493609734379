<template>
    <div v-if="dataLoading" class="py-lg-9">
        <div class="text-center pt-7 pb-7">
            <loading-animation class="text-primary text-5x"/>
        </div>
    </div>
    <div v-else class="py-6">
        <div class="container">
            <div class="col-12">
                <heading-with-info :bottom-tag="true" title="Introducing our Multifaceted Experts’ Team"
                                   heading="Empowering Success:Your All-In-One Hub for Multifaceted Business Excellence"
                                   info="
                                   We are a creative team of quickly motivated and goal-oriented individuals who not
                                    only bring our own professional virtues, innovative traits, unmatched skills,
                                     and ideologies but can elevate each others’ talent and strength.
                                      In the same manner, we can figure out crack the most stringent
                                       dares and offer real-time impactful answers to our clients with
                                        extreme ease and matchless accuracy."></heading-with-info>
            </div>
            <div class="col-12" :id="m.id" v-for="(m,i) in details" :key="i">
                <div class="py-lg-7 py-4">
                    <member-large-card :siteMap="m.team_sitemap" :image-align="i%2 === 0 ? 'left' : 'right'"
                                       icon="fa fa-user" :heading="m.name"
                                       :role="m.designation"
                                       :image="m.team_member_image">
                        <template #content>
                            <p class="align-justify">{{ m.full_description }}</p>
                        </template>
                    </member-large-card>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import MemberLargeCard from '../components/home/MemberLargeCard';
import HeadingWithInfo from '@components/HeadingWithInfo';

import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'ProfessionalTeamPage',
    components: { HeadingWithInfo, MemberLargeCard },
    data () {
        return {
            details: '',
            dataLoading: false
        };
    },
    beforeMount () {
        this.loadDetails();
        // this.goTO();
    },
    methods: {
        goTO () {
            if (this.$route.hash) {
                window.location.href = this.$route.hash;
                this.scrollToTop();
            }
        },
        scrollToTop () {
            window.scrollBy(0, -150);
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.team);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                setTimeout(this.goTO, 100);
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
